import {
    createEntityAdapter,
    createSlice,
    PayloadAction,
} from '@reduxjs/toolkit'
import { Toast } from './ToastType'
import { RootState } from '../../store'

const toastAdapter = createEntityAdapter<Toast>({
    sortComparer: (a, b) => a.timestamp - b.timestamp,
})

const toastSlice = createSlice({
    name: 'toasts',
    initialState: toastAdapter.getInitialState({}),
    reducers: {
        addToast: (state, action: PayloadAction<Toast>) => {
            toastAdapter.addOne(state, action.payload)
        },
        removeToast: (state, action: PayloadAction<string>) => {
            toastAdapter.removeOne(state, action.payload)
        },
    },
})

export const { addToast, removeToast } = toastSlice.actions

export const {
    selectAll: selectAllToasts,
    selectById: selectToastById,
    selectIds,
} = toastAdapter.getSelectors<RootState>((state) => state.toasts)

export default toastSlice.reducer
