import React from 'react'
import './Patient.css'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { ConnectedProps, connect } from 'react-redux'
import {
    IonButton,
    IonButtons,
    IonCol,
    IonGrid,
    IonInput,
    IonItem,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonPage,
    IonHeader,
    IonToolbar,
    IonContent,
    IonBackButton,
} from '@ionic/react'
import { IonTitle } from '@ionic/react'
import { Formik, FormikProps, FormikValues } from 'formik'
import { fetchAllCourses, selectAllCourses } from '../Course/CourseSlice'
import {
    createPatient,
    fetchPatientById,
    selectPatientById,
    updatePatient,
} from './PatientSlice'
import { RootState } from '../../store'
import { Course } from '../Course/CourseType'

// const compareWith = (o1: number, o2: number) => {
//     if (!o1 || !o2) {
//         return o1 === o2
//     }

//     if (Array.isArray(o2)) {
//         return o2.some((o) => o.id === o1.id)
//     }

//     return o1.id === o2.id
// }

type PatientEditProps = {}

type PatientEditState = {
    dataLoading: boolean
}

type RouterProps = RouteComponentProps<{ id: string }>

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = PatientEditProps & RouterProps & PropsFromRedux

class PatientEdit extends React.Component<Props, PatientEditState> {
    state: PatientEditState = {
        dataLoading: true,
    }

    componentDidMount() {
        const { fetchAllCourses, fetchPatientById, patientId, mode } =
            this.props

        let promises
        if (mode === 'edit' && patientId) {
            promises = [fetchAllCourses(), fetchPatientById({ id: patientId })]
        } else {
            promises = [fetchAllCourses()]
        }

        this.setState({ dataLoading: true })
        Promise.all(promises)
            .then(() => {
                this.setState({ dataLoading: false })
            })
            .catch(() => {
                this.setState({ dataLoading: false })
            })
    }

    handleSubmitForm = (values: FormikValues) => {
        const { updatePatient, patientId, createPatient, history } = this.props

        Promise.all([
            patientId
                ? updatePatient({ data: values, id: patientId })
                : createPatient({ data: values }),
        ])
            .then(() => {
                history.push('/patients')
            })
            .catch(() => {
                this.setState({ dataLoading: false })
            })
    }

    renderCourseOptions = (course: Course, index: number) => {
        return (
            <IonSelectOption value={course.id} key={index}>
                {course.name}
            </IonSelectOption>
        )
    }

    renderFieldset = (props: FormikProps<FormikValues>) => {
        const { handleChange, values, handleSubmit, setFieldValue } = props
        const { courses, patientId, mode } = this.props

        return (
            <IonGrid justify-content-center>
                <IonRow>
                    <IonCol size="12" size-sm="6">
                        <IonInput
                            name="firstName"
                            value={values.firstName ? values.firstName : ''}
                            onIonChange={handleChange}
                            fill="outline"
                            label="Vorname"
                            labelPlacement="floating"
                        ></IonInput>
                    </IonCol>
                    <IonCol size="12" size-sm="6">
                        <IonInput
                            name="lastName"
                            value={values.lastName ? values.lastName : ''}
                            onIonChange={handleChange}
                            fill="outline"
                            label="Nachname"
                            labelPlacement="floating"
                        ></IonInput>
                    </IonCol>
                    <IonCol size="12" size-sm="6">
                        <IonSelect
                            onIonChange={(e) => {
                                setFieldValue('gender', e.detail.value, false)
                            }}
                            fill="outline"
                            label="Geschlecht"
                            labelPlacement="floating"
                            name="gender"
                            value={values.gender}
                            interface="action-sheet"
                            style={{ maxWidth: '100% !important' }}
                        >
                            <IonSelectOption key={'m'} value={'m'}>
                                männlich
                            </IonSelectOption>
                            <IonSelectOption key={'f'} value={'f'}>
                                weiblich
                            </IonSelectOption>
                            <IonSelectOption key={'d'} value={'d'}>
                                divers
                            </IonSelectOption>
                        </IonSelect>
                    </IonCol>
                    <IonCol size="12" size-sm="6">
                        <IonInput
                            name="yearOfBirth"
                            value={values.yearOfBirth ? values.yearOfBirth : ''}
                            fill="outline"
                            label="Geburtsjahr"
                            labelPlacement="floating"
                            onIonChange={handleChange}
                        ></IonInput>
                        {/* <IonButton id="open-modal">
                            Open Datetime Modal
                        </IonButton>
                        <IonModal trigger="open-modal">
                            <IonDatetime
                                presentation="date"
                                preferWheel={true}
                            ></IonDatetime>
                        </IonModal> */}
                    </IonCol>
                    <IonCol size="12" size-sm="6">
                        <IonInput
                            name="street"
                            fill="outline"
                            labelPlacement="floating"
                            label="Straße"
                            value={values.street ? values.street : ''}
                            onIonChange={handleChange}
                        ></IonInput>
                    </IonCol>
                    <IonCol size="12" size-sm="6">
                        <IonInput
                            type="number"
                            name="houseNumber"
                            fill="outline"
                            labelPlacement="floating"
                            label="Hausnummer"
                            value={values.houseNumber ? values.houseNumber : ''}
                            onIonChange={handleChange}
                        ></IonInput>
                    </IonCol>
                    <IonCol size="12" size-sm="6">
                        <IonInput
                            type="number"
                            name="zip"
                            fill="outline"
                            labelPlacement="floating"
                            label="PLZ"
                            value={values.zip ? values.zip : ''}
                            onIonChange={handleChange}
                        ></IonInput>
                    </IonCol>
                    <IonCol size="12" size-sm="6">
                        <IonInput
                            name="city"
                            fill="outline"
                            labelPlacement="floating"
                            label="Stadt"
                            value={values.city ? values.city : ''}
                            onIonChange={handleChange}
                        ></IonInput>
                    </IonCol>
                    <IonCol size="12" size-sm="6">
                        <IonInput
                            name="occupation"
                            fill="outline"
                            labelPlacement="floating"
                            label="Beruf"
                            value={values.occupation ? values.occupation : ''}
                            onIonChange={handleChange}
                        ></IonInput>
                    </IonCol>
                    <IonCol size="12" size-sm="6">
                        <IonInput
                            name="hobbies"
                            fill="outline"
                            labelPlacement="floating"
                            label="Hobbies"
                            value={values.hobbies ? values.hobbies : ''}
                            onIonChange={handleChange}
                        ></IonInput>
                    </IonCol>
                    <IonCol size="12" size-sm="6">
                        <IonInput
                            name="anthropometricData"
                            fill="outline"
                            labelPlacement="floating"
                            label="Anthropometrische Daten"
                            value={
                                values.anthropometricData
                                    ? values.anthropometricData
                                    : ''
                            }
                            onIonChange={handleChange}
                        ></IonInput>
                    </IonCol>
                    <IonCol size="12" size-sm="6">
                        <IonItem style={{ maxWidth: '100% !important' }}>
                            <IonSelect
                                disabled
                                interfaceOptions={{
                                    header: 'Kurs',
                                    subHeader: 'Wählen Sie den Kurs aus.',
                                }}
                                interface="alert"
                                fill="outline"
                                labelPlacement="floating"
                                label="Kurs"
                                name="courses"
                                onIonChange={(e) => {
                                    console.log(e)
                                    props.setFieldValue(
                                        'courses',
                                        e.detail.value
                                    )
                                }}
                                //compareWith={compareWith}
                                value={values.courses ? values.courses : []}
                                multiple={true}
                            >
                                {courses.map((course, index) =>
                                    this.renderCourseOptions(course, index)
                                )}
                            </IonSelect>
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow className="ion-align-self-end">
                    <IonButton onClick={() => handleSubmit()}>
                        Speichern
                    </IonButton>
                    {mode === 'edit' && (
                        <IonButton routerLink={'/patients/' + patientId}>
                            Zurück zur Patientenübersicht
                        </IonButton>
                    )}
                </IonRow>
            </IonGrid>
        )
    }

    render() {
        const { dataLoading } = this.state
        const { patient, mode } = this.props

        if (dataLoading) {
            return null
        }

        return (
            <IonPage>
                <IonHeader>
                    <IonToolbar mode="ios">
                        <IonButtons slot="start">
                            <IonBackButton
                                text=""
                                defaultHref={`/patients/`}
                            ></IonBackButton>
                        </IonButtons>
                        <IonTitle>Neuen Patienten anlegen</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonContent fullscreen>
                    <Formik
                        initialValues={
                            mode === 'edit'
                                ? ({ ...patient } as FormikValues)
                                : { courses: [1] }
                        }
                        onSubmit={this.handleSubmitForm}
                    >
                        {(props) => (
                            <form
                                onSubmit={props.handleSubmit}
                                className="full-width"
                            >
                                {this.renderFieldset(props)}
                            </form>
                        )}
                    </Formik>
                </IonContent>
            </IonPage>
        )
    }
}

const mapState = (state: RootState, ownProps: RouterProps) => {
    const mode = ownProps.match.path.endsWith('/new') ? 'new' : 'edit'
    const id = parseInt(ownProps.match.params.id)

    return {
        mode: mode,
        patientId: id,
        patient: selectPatientById(state, id),
        courses: selectAllCourses(state),
    }
}

const mapDispatch = {
    fetchAllCourses,
    updatePatient,
    createPatient,
    fetchPatientById,
}

const connector = connect(mapState, mapDispatch)

export default withRouter(connector(PatientEdit))
