import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { normalize } from 'normalizr'
import { Session } from '../../utils/SessionType'
import axiosInstance, { API_ROOT } from '../../utils/ApiConfig'
import { Schemas } from '../../schemas'
import { User } from '../User/UserType'
import { get, remove, set } from '../../data/IonicStorage'
import { LoginForm } from '../Login/Login'
import { FormikValues } from 'formik'

export const setAccessToken = createAsyncThunk(
    'auth/setToken',
    async (token: string) => {
        await set('jwt', token)
    }
)

export const getAccessToken = createAsyncThunk('auth/getToken', async () => {
    const token = await get('jwt')
    return token
})

const initialState = {
    loading: true,
    loggedIn: false,
} as Session

export const fetchSession = createAsyncThunk('session/getSession', async () => {
    const response = await axiosInstance.get(API_ROOT + '/users/session', {
        withCredentials: true,
    })

    if (response.status !== 200) {
        await remove('jwt')
    }

    const { loggedIn, ...user } = response.data
    return { loggedIn: loggedIn, user: user }
})

export const loginUser = createAsyncThunk(
    'auth/login',
    async (data: LoginForm) => {
        //const header = await getHeader();
        const response = await axiosInstance.post('/auth/login', data)
        await set('jwt', response.data.accessToken)

        const normalized = normalize<
            any,
            {
                user: Record<string, User>
            }
        >(response.data, Schemas.User)

        return {
            entities: normalized.entities,
            user: Object.values(normalized.entities.user)[0],
            message: response.data.message,
        }
    }
)

export const registerUser = createAsyncThunk(
    'auth/register',
    async (params: { data: FormikValues }) => {
        const { data } = params

        const response = await axiosInstance.post('/auth/register', data)
        return { message: response.data.message }
    }
)

export const logoutUser = createAsyncThunk('auth/logout', async () => {
    const response = await axiosInstance.post('/auth/logout')
    await remove('jwt')

    if (response.status === 200) {
        return { loggedIn: false, message: response.data.message }
    }
})

const sessionSlice = createSlice({
    name: 'session',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchSession.pending, (state) => {
            state.loading = true
        })
        builder.addCase(fetchSession.fulfilled, (state, action) => {
            const { user, loggedIn } = action.payload

            state.loading = false
            state.loggedIn = loggedIn
            state.user = user
        })
        builder.addCase(fetchSession.rejected, (state) => {
            state.loading = false
        })
        builder.addCase(loginUser.fulfilled, (state, action) => {
            const { user } = action.payload

            state.loggedIn = true
            state.user = user
        })
        builder.addCase(logoutUser.fulfilled, (state) => {
            return { loggedIn: false, loading: false }
        })
    },
})

export default sessionSlice.reducer
