import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { ConnectedProps, connect } from 'react-redux'
import {
    IonButtons,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonIcon,
    IonItem,
    IonLabel,
    IonButton,
    IonModal,
    IonList,
    IonSpinner,
    IonListHeader,
    IonSearchbar,
    IonCheckbox,
    IonPopover,
    SearchbarCustomEvent,
} from '@ionic/react'
import { informationCircleOutline, removeCircleOutline } from 'ionicons/icons'
import { Formik, FormikProps, FormikValues } from 'formik'
import { isEmpty, without } from 'lodash'
import {
    createTestSelection,
    updateTestSelection,
    deleteTestSelectionById,
    fetchTestSelectionById,
} from '../../TestSelection/TestSelectionSlice'
import { makeTestsByTestSelection, makeTestSelectionByCase } from '../selectors'
import { Virtuoso } from 'react-virtuoso'
import { fetchCaseById, selectCaseById } from '../CaseSlice'
import { fetchAllTests, selectAllTests } from '../../Test/TestSlice'
import MyAccordion from '../../../components/MyAccordion'
import DeleteDialog from '../../../components/DeleteDialog/DeleteDialog'
import AddDataButton from '../../../components/AddDataButton'
import { RootState } from '../../../store'
import { Test } from '../../Test/TestType'
import { makeTestsByIcf } from '../selectors'

type TestSelectionProps = {
    disabled: boolean
    dataLoading: boolean
}

type TestSelectionState = {
    isOpen: boolean
    searchValue: string | null
    popoverOpen: string | boolean
    popoverContent?: { name: string; label: string }
    deleteDialogOpen: boolean
    optionToDelete: number | null
    filteredTests?: Test[]
}

type RouterProps = RouteComponentProps<{
    caseId: string
}>

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = TestSelectionProps & RouterProps & PropsFromRedux

class TestSelection extends React.Component<Props, TestSelectionState> {
    state: TestSelectionState = {
        isOpen: false,
        searchValue: null,
        deleteDialogOpen: false,
        optionToDelete: null,
        popoverOpen: false,
    }

    componentDidMount = async () => {
        const { fetchAllTests } = this.props

        await fetchAllTests()
        this.setState({
            isOpen: false,
        })
        this.initializeSearch()
    }

    componentDidUpdate = (_prevProps: Props, prevState: TestSelectionState) => {
        if (prevState.isOpen && !this.state.isOpen) {
            let filteredTests = this.useTestFilter('')

            this.setState({
                filteredTests: filteredTests,
            })
        }
    }

    initializeSearch = () => {
        const { tests } = this.props

        this.setState({ filteredTests: tests })
    }

    handleSubmitData = async (values: FormikValues) => {
        const {
            caseId,
            testSelectionByCase,
            updateTestSelection,
            createTestSelection,
            deleteTestSelectionById,
            fetchCaseById,
        } = this.props

        try {
            if (!isEmpty(testSelectionByCase)) {
                if (!isEmpty(values.tests)) {
                    await updateTestSelection({
                        data: values,
                        id: testSelectionByCase.id,
                    })
                } else {
                    await deleteTestSelectionById({
                        id: testSelectionByCase.id,
                    })
                }
            } else {
                await createTestSelection({ data: values })
            }
            await fetchCaseById({ id: caseId })
            this.setState({ isOpen: false })
        } catch (err) {
            console.error(err)
        }
    }

    handleDeleteTest = async (id: number) => {
        const {
            testSelectionByCase,
            updateTestSelection,
            deleteTestSelectionById,
        } = this.props

        if (!testSelectionByCase) {
            return
        }

        try {
            const values = {
                ...testSelectionByCase,
                tests: without(testSelectionByCase.tests, id),
            }
            if (!isEmpty(values.tests)) {
                await updateTestSelection({
                    data: values,
                    id: testSelectionByCase.id,
                })
            } else {
                await deleteTestSelectionById({ id: testSelectionByCase.id })
            }
            this.setState({
                deleteDialogOpen: false,
                optionToDelete: null,
            })
        } catch (err) {
            console.error(err)
        }
    }

    deleteTest = (id: number) => {
        this.setState({ deleteDialogOpen: true, optionToDelete: id })
    }

    useTestFilter = (value: string) => {
        const { tests } = this.props

        return tests.filter((test) => {
            if (isEmpty(value)) {
                return true
            }
            return test.name?.toLowerCase().includes(value.toLowerCase())
        })
    }

    handleSearchChange = (e: SearchbarCustomEvent) => {
        let filteredTests = this.useTestFilter(e.detail.value ?? '')

        this.setState({
            filteredTests: filteredTests,
        })
    }

    handleItemClick = (test: Test, props: FormikProps<FormikValues>) => {
        const { values, setFieldValue } = props
        let tests = [...values.tests]

        const index = tests.indexOf(test.id)

        if (index > -1) {
            tests.splice(index, 1)
        } else {
            tests.push(test.id)
        }

        setFieldValue('tests', tests, false)
    }

    renderDeleteDialog = () => {
        const { deleteDialogOpen, optionToDelete } = this.state
        const { testsAsObject } = this.props

        if (!optionToDelete) {
            return null
        }

        let header =
            optionToDelete + ' - ' + testsAsObject[optionToDelete]?.name

        return (
            <>
                <DeleteDialog
                    header={header + ' wirklich löschen?'}
                    onDelete={() => {
                        this.handleDeleteTest(optionToDelete)
                    }}
                    onDidDismiss={() =>
                        this.setState({ deleteDialogOpen: false })
                    }
                    isOpen={deleteDialogOpen}
                />
            </>
        )
    }

    renderTest = (
        test: Test,
        index: number,
        showCheckbox: boolean = true,
        props?: FormikProps<FormikValues>
    ) => {
        if (!showCheckbox || !props) {
            return (
                <IonItem key={index}>
                    <>
                        {test.id} {' - '}
                        {test.name}
                    </>
                    {test.shortDescription && (
                        <IonButton
                            id={'info-' + test.id}
                            onClick={() => {
                                this.setState({
                                    popoverOpen: true,
                                    popoverContent: {
                                        name: test.name,
                                        label: test.shortDescription,
                                    },
                                })
                            }}
                            shape="round"
                            fill="clear"
                            slot="end"
                        >
                            <IonIcon
                                slot="icon-only"
                                icon={informationCircleOutline}
                            />
                        </IonButton>
                    )}
                    <IonButton
                        onClick={() => this.deleteTest(test.id)}
                        shape="round"
                        fill="clear"
                    >
                        <IonIcon
                            color="danger"
                            slot="icon-only"
                            icon={removeCircleOutline}
                        />
                    </IonButton>
                </IonItem>
            )
        }

        const { values } = props

        return (
            <IonItem
                key={index}
                button
                onClick={() => this.handleItemClick(test, props)}
            >
                <IonLabel>
                    {test.id} {' - '}
                    {test.name}
                </IonLabel>
                {test.shortDescription && (
                    <IonButton
                        id={'info-' + test.id}
                        onClick={(e) => {
                            e.stopPropagation()
                            this.setState({
                                popoverOpen: true,
                                popoverContent: {
                                    name: test.name,
                                    label: test.shortDescription,
                                },
                            })
                        }}
                        shape="round"
                        fill="clear"
                        slot="end"
                    >
                        <IonIcon
                            slot="icon-only"
                            icon={informationCircleOutline}
                        />
                    </IonButton>
                )}
                <IonCheckbox
                    checked={values['tests'].includes(test.id)}
                    slot="end"
                ></IonCheckbox>
            </IonItem>
        )
    }

    renderSearchResults = (props: FormikProps<FormikValues>) => {
        const { testsByIcf } = this.props
        const { filteredTests } = this.state

        return (
            <IonList style={{ height: '88%' }}>
                {filteredTests && !isEmpty(filteredTests) && (
                    <>
                        <Virtuoso
                            className="ion-content-scroll-host"
                            style={{ height: '100%' }}
                            totalCount={
                                filteredTests.length + testsByIcf.length
                            }
                            itemContent={(index) => (
                                <>
                                    {index < testsByIcf.length ? (
                                        <div>
                                            {index === 0 && (
                                                <IonListHeader>
                                                    Empfohlen
                                                </IonListHeader>
                                            )}
                                            {this.renderTest(
                                                testsByIcf[index],
                                                index,
                                                true,
                                                props
                                            )}
                                        </div>
                                    ) : (
                                        <div>
                                            {index === testsByIcf.length && (
                                                <IonListHeader>
                                                    Tests
                                                </IonListHeader>
                                            )}
                                            {this.renderTest(
                                                filteredTests[
                                                    index - testsByIcf.length
                                                ],
                                                index,
                                                true,
                                                props
                                            )}
                                        </div>
                                    )}
                                </>
                            )}
                        />
                    </>
                )}
            </IonList>
        )
    }

    renderModal = () => {
        const { caseId, testSelectionByCase } = this.props
        const { isOpen } = this.state

        return (
            <IonModal
                isOpen={isOpen}
                onDidDismiss={() => {
                    this.setState({ isOpen: false })
                }}
            >
                <Formik
                    initialValues={
                        (testSelectionByCase
                            ? { ...testSelectionByCase }
                            : {
                                  caseId: caseId,
                                  tests: [],
                              }) as FormikValues
                    }
                    onSubmit={this.handleSubmitData}
                >
                    {(props) => (
                        <form
                            onSubmit={props.handleSubmit}
                            className="full-width-form"
                        >
                            <IonHeader style={{ height: '8%' }}>
                                <IonToolbar>
                                    <IonButtons slot="start">
                                        <IonButton
                                            color="medium"
                                            onClick={() =>
                                                this.setState({ isOpen: false })
                                            }
                                        >
                                            Abbrechen
                                        </IonButton>
                                    </IonButtons>
                                    <IonTitle>Testauswahl</IonTitle>
                                    <IonButtons slot="end">
                                        {props.isSubmitting ? (
                                            <IonSpinner />
                                        ) : (
                                            <IonButton
                                                onClick={props.submitForm}
                                            >
                                                Speichern
                                            </IonButton>
                                        )}
                                    </IonButtons>
                                </IonToolbar>
                            </IonHeader>
                            <IonContent
                                scrollY={false}
                                style={{ height: '92%' }}
                            >
                                <IonSearchbar
                                    onIonInput={this.handleSearchChange}
                                    style={{ height: '12%' }}
                                    autocapitalize="off"
                                />
                                {this.renderSearchResults(props)}
                            </IonContent>
                        </form>
                    )}
                </Formik>
            </IonModal>
        )
    }

    renderAddData = () => {
        const { disabled } = this.props

        return (
            <div className="index-main">
                <p style={{ paddingTop: '16px' }}>Test hinzufügen</p>
                <AddDataButton
                    disabled={disabled}
                    onClick={() => this.setState({ isOpen: true })}
                />
            </div>
        )
    }

    renderTestSelection = () => {
        const { testsByTestSelection } = this.props

        return (
            <>
                <IonList>
                    {testsByTestSelection && !isEmpty(testsByTestSelection) && (
                        <>
                            <IonListHeader>Tests</IonListHeader>
                            {Object.values(testsByTestSelection).map(
                                (test, index) =>
                                    this.renderTest(test, index, false)
                            )}
                        </>
                    )}
                </IonList>

                {this.renderAddData()}
                {this.renderDeleteDialog()}
            </>
        )
    }

    render() {
        const { dataLoading, testSelectionByCase } = this.props
        const { popoverContent, popoverOpen } = this.state

        if (dataLoading) {
            return null
        }

        const dataAvailable = !isEmpty(testSelectionByCase)

        return (
            <>
                <MyAccordion
                    title={`Testauswahl (${
                        dataAvailable ? testSelectionByCase.tests.length : '0'
                    })`}
                >
                    {dataAvailable
                        ? this.renderTestSelection()
                        : this.renderAddData()}
                </MyAccordion>
                {this.renderModal()}
                <IonPopover
                    isOpen={Boolean(popoverOpen)}
                    onDidDismiss={() => this.setState({ popoverOpen: false })}
                    side="left"
                    alignment="center"
                    className="popover-content"
                >
                    <IonContent class="ion-padding">
                        <div>
                            <h3>{popoverContent?.name}</h3>
                            <p>{popoverContent?.label}</p>
                        </div>
                    </IonContent>
                </IonPopover>
            </>
        )
    }
}

const makeMapState = () => {
    const getTestSelectionByCase = makeTestSelectionByCase()
    const getTestsByTestSelection = makeTestsByTestSelection()
    const getTestsByIcf = makeTestsByIcf()

    return (state: RootState, ownProps: RouterProps) => {
        const caseId = parseInt(ownProps.match.params.caseId)
        const testSelectionByCase = getTestSelectionByCase(state, caseId)
        const testsByTestSelection = getTestsByTestSelection(state, caseId)
        const testsByIcf = getTestsByIcf(state, caseId)

        return {
            tests: selectAllTests(state),
            testsAsObject: state.test?.entities,
            testSelectionByCase: testSelectionByCase,
            testsByTestSelection: testsByTestSelection,
            thisCase: selectCaseById(state, caseId),
            testsByIcf: testsByIcf,
            caseId: caseId,
        }
    }
}

const mapDispatch = {
    createTestSelection,
    updateTestSelection,
    fetchTestSelectionById,
    deleteTestSelectionById,
    fetchAllTests,
    fetchCaseById,
}

const connector = connect(makeMapState, mapDispatch)

export default withRouter(connector(TestSelection))
