import {
    ListenerEffect,
    ListenerEffectAPI,
    PayloadAction,
    UnknownAction,
    UnsubscribeListener,
} from '@reduxjs/toolkit'
import { AppDispatch, AppStartListening, RootState } from '../../store'
import { addToast } from '../../components/Toasts/toastSlice'

type Payload = {
    message: {
        text: string
        type: 'error' | 'success' | 'warning' | 'info'
        toast: boolean
    }
}

function isMessagePayloadAction(
    action: UnknownAction
): action is PayloadAction<Payload> {
    return (
        action.type !== undefined &&
        action.payload !== null &&
        typeof action.payload === 'object' &&
        'message' in action.payload &&
        typeof action.payload.message === 'object' &&
        typeof (action.payload as Payload).message.text === 'string' &&
        ['error', 'success', 'warning', 'info'].includes(
            (action.payload as Payload).message.type
        ) &&
        typeof (action.payload as Payload).message.toast === 'boolean' &&
        (action.payload as Payload).message.toast
    )
}

const toastEffect: ListenerEffect<
    UnknownAction,
    RootState,
    AppDispatch,
    unknown
> = (
    action: UnknownAction,
    listenerApi: ListenerEffectAPI<RootState, AppDispatch>
) => {
    if (isMessagePayloadAction(action)) {
        listenerApi.dispatch(
            addToast({
                id: action.type,
                timestamp: Date.now(),
                message: action.payload.message.text,
                type: action.payload.message.type,
            })
        )
    }
}

const toastPredicate = (action: UnknownAction) => {
    return isMessagePayloadAction(action) ? true : false
}

export function setupToastListener(
    startListening: AppStartListening
): UnsubscribeListener {
    const listeners = [
        startListening({
            predicate: toastPredicate,
            effect: toastEffect,
        }),
    ]

    return () => listeners.forEach((unsubscribe) => unsubscribe())
}
