import { get } from '../data/IonicStorage'
import axios from 'axios'

const getJwt = async () => {
    const out = await get('jwt')
    return out
}

// export const getHeader = async () => {
//   const token = await getJwt();
//   const axiosConfig: AxiosRequestConfig = {
//     headers: { Authorization: `Bearer ${token}` },
//     withCredentials: true,
//   };
//   return axiosConfig.headers; // Nur die Header zurückgeben
// };

export const API_ROOT =
    process.env.NODE_ENV !== 'production'
        ? 'http://localhost:4000/api'
        : 'https://asa.gw.hs-fulda.de/api'

const axiosInstance = axios.create({
    baseURL: API_ROOT,
})

// Fügen Sie einen Interceptor hinzu, um den Header hinzuzufügen
axiosInstance.interceptors.request.use(
    async (config) => {
        const token = await getJwt()
        config.headers['Authorization'] = `Bearer ${token}`
        config.withCredentials = true

        return config
    },
    (error) => {
        // Hier können Sie Fehler behandeln, wenn nötig
        return Promise.reject(error)
    }
)

export default axiosInstance
