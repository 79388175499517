import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { ConnectedProps, connect } from 'react-redux'
import {
    IonButtons,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonIcon,
    IonItem,
    IonButton,
    IonModal,
    IonList,
    IonSpinner,
    IonListHeader,
    IonSearchbar,
    IonCheckbox,
    IonPopover,
    SearchbarCustomEvent,
} from '@ionic/react'
import { informationCircleOutline, removeCircleOutline } from 'ionicons/icons'
import { Formik, FormikProps, FormikValues } from 'formik'
import { isEmpty, without } from 'lodash'
import { selectCaseById, updateCase } from '../CaseSlice'
import { Virtuoso } from 'react-virtuoso'
import MyAccordion from '../../../components/MyAccordion'
import { searchIcd } from '../../../utils/IcfIcdHandler'
import DeleteDialog from '../../../components/DeleteDialog/DeleteDialog'
import AddDataButton from '../../../components/AddDataButton'
import { RootState } from '../../../store'
import { IcfIcdEntry, SearchResultIcd } from '../../../utils/IcfIcdType'
import { makeIcdsByCase } from '../selectors'

type CaseIcdProps = {
    disabled: boolean
    dataLoading: boolean
}

type CaseIcdState = {
    isOpen: boolean
    searchValue: string | null
    popoverOpen: string | boolean
    popoverContent?: IcfIcdEntry
    deleteDialogOpen: boolean
    searchResults: SearchResultIcd
    optionToDelete: string | null
}

type RouterProps = RouteComponentProps<{
    caseId: string
}>

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = CaseIcdProps & RouterProps & PropsFromRedux

class CaseIcd extends React.Component<Props, CaseIcdState> {
    state: CaseIcdState = {
        isOpen: false,
        searchValue: null,
        popoverOpen: false,
        deleteDialogOpen: false,
        searchResults: { items: [], itemsCount: 0 },
        optionToDelete: null,
    }
    componentDidMount() {
        this.initializeSearch()
    }

    initializeSearch = () => {
        let filter = searchIcd('')
        this.setState({ searchResults: filter })
    }

    handleSubmitData = (values: FormikValues) => {
        const { caseId, updateCase } = this.props

        const promises = [updateCase({ data: values, id: caseId })]

        Promise.all(promises).then((val) => {
            this.setState({ isOpen: false })
        })
    }

    // useIcdFilter = (filter, value) => {
    //     return icdData[filter].filter((chapter) => {
    //         if (isEmpty(value)) {
    //             return true
    //         }
    //         return (
    //             chapter.code.toLowerCase().startsWith(value.toLowerCase()) ||
    //             chapter.label.toLowerCase().includes(value.toLowerCase())
    //         )
    //     })
    // }

    handleSearchChange = (e: SearchbarCustomEvent) => {
        let filter = searchIcd(e.detail.value ?? '')
        this.setState({ searchResults: filter })
    }

    deleteIcD = (code: string) => {
        this.setState({ deleteDialogOpen: true, optionToDelete: code })
    }

    handleDeleteIcd = (code: string) => {
        const { thisCase, caseId, updateCase } = this.props

        const values = {
            ...thisCase,
            caseIcds: without(thisCase?.caseIcds, code),
        }
        const promises = [updateCase({ data: values, id: caseId })]

        Promise.all(promises).then(() => {
            this.setState({ deleteDialogOpen: false, optionToDelete: null })
        })
    }

    handleItemClick = (icd: IcfIcdEntry, props: FormikProps<FormikValues>) => {
        const { values, setFieldValue } = props
        let caseIcds = [...values.caseIcds]

        const index = caseIcds.indexOf(icd.code)

        if (index > -1) {
            caseIcds.splice(index, 1)
        } else {
            caseIcds.push(icd.code)
        }

        setFieldValue('caseIcds', caseIcds, false)
    }

    renderIcd = (
        icd: IcfIcdEntry,
        index: number,
        showCheckbox: boolean = true,
        props?: FormikProps<FormikValues>
    ) => {
        if (!showCheckbox || !props) {
            return (
                <IonItem key={index}>
                    <>
                        {' '}
                        {icd.code} {' - '}
                        {icd.label}
                    </>
                    <IonButton
                        id={'info-' + icd.code}
                        onClick={() => {
                            this.setState({
                                popoverOpen: 'info-' + icd.code,
                                popoverContent: icd,
                            })
                        }}
                        shape="round"
                        fill="clear"
                        slot="end"
                    >
                        <IonIcon
                            slot="icon-only"
                            icon={informationCircleOutline}
                        />
                    </IonButton>
                    <IonButton
                        onClick={() => this.deleteIcD(icd.code)}
                        shape="round"
                        fill="clear"
                    >
                        <IonIcon
                            color="danger"
                            slot="icon-only"
                            icon={removeCircleOutline}
                        />
                    </IonButton>
                </IonItem>
            )
        }

        const { values } = props

        return (
            <IonItem
                key={index}
                onClick={() => this.handleItemClick(icd, props)}
            >
                <>
                    {' '}
                    {icd.code} {' - '}
                    {icd.label}
                </>
                <IonButton
                    id={'info-' + icd.code}
                    onClick={(e) => {
                        e.stopPropagation()
                        this.setState({
                            popoverOpen: 'info-' + icd.code,
                            popoverContent: icd,
                        })
                    }}
                    shape="round"
                    fill="clear"
                    slot="end"
                >
                    <IonIcon slot="icon-only" icon={informationCircleOutline} />
                </IonButton>
                <IonCheckbox
                    checked={values['caseIcds'].includes(icd.code)}
                    slot="end"
                ></IonCheckbox>
            </IonItem>
        )
    }

    renderSearchResults = (props: FormikProps<FormikValues>) => {
        const { items, itemsCount } = this.state.searchResults

        return (
            <IonList style={{ height: '88%' }}>
                {!isEmpty(items) && (
                    <>
                        <Virtuoso
                            className="ion-content-scroll-host"
                            style={{ height: '100%' }}
                            totalCount={itemsCount}
                            itemContent={(index) => (
                                <div>
                                    {index === 0 && (
                                        <IonListHeader>Kategorie</IonListHeader>
                                    )}
                                    {this.renderIcd(
                                        items[index],
                                        index,
                                        true,
                                        props
                                    )}
                                </div>
                            )}
                        />
                    </>
                )}
            </IonList>
        )
    }

    renderModal = () => {
        const { thisCase } = this.props
        const { isOpen } = this.state

        return (
            <IonModal
                isOpen={isOpen}
                onDidDismiss={() => {
                    this.setState({ isOpen: false })
                }}
                className="fullscreen"
            >
                <Formik
                    initialValues={
                        { caseIcds: thisCase?.caseIcds ?? [] } as FormikValues
                    }
                    onSubmit={this.handleSubmitData}
                >
                    {(props) => (
                        <form
                            onSubmit={props.handleSubmit}
                            className="full-width-form"
                        >
                            <IonHeader style={{ height: '8%' }}>
                                <IonToolbar>
                                    <IonButtons slot="start">
                                        <IonButton
                                            color="medium"
                                            onClick={() =>
                                                this.setState({ isOpen: false })
                                            }
                                        >
                                            Abbrechen
                                        </IonButton>
                                    </IonButtons>
                                    <IonTitle>ICD</IonTitle>
                                    <IonButtons slot="end">
                                        {props.isSubmitting ? (
                                            <IonSpinner />
                                        ) : (
                                            <IonButton
                                                onClick={props.submitForm}
                                            >
                                                Speichern
                                            </IonButton>
                                        )}
                                    </IonButtons>
                                </IonToolbar>
                            </IonHeader>
                            <IonContent
                                scrollY={false}
                                style={{ height: '92%' }}
                            >
                                <IonSearchbar
                                    onIonInput={this.handleSearchChange}
                                    style={{ height: '12%' }}
                                    autocapitalize="off"
                                />
                                {this.renderSearchResults(props)}
                            </IonContent>
                        </form>
                    )}
                </Formik>
            </IonModal>
        )
    }

    renderDeleteDialog = () => {
        const { deleteDialogOpen, optionToDelete } = this.state

        if (!optionToDelete) {
            return null
        }

        return (
            <>
                <DeleteDialog
                    header={optionToDelete + ' wirklich löschen?'}
                    onDelete={() => {
                        this.handleDeleteIcd(optionToDelete)
                    }}
                    onDidDismiss={() =>
                        this.setState({ deleteDialogOpen: false })
                    }
                    isOpen={deleteDialogOpen}
                />
            </>
        )
    }

    renderAddData = () => {
        const { disabled } = this.props

        return (
            <div className="index-main">
                <p style={{ paddingTop: '16px' }}>ICD hinzufügen</p>
                <AddDataButton
                    disabled={disabled}
                    onClick={() => this.setState({ isOpen: true })}
                />
            </div>
        )
    }

    renderCaseIcds = () => {
        const { icdsByCase } = this.props

        return (
            <>
                <IonList>
                    {!isEmpty(icdsByCase['category']) && (
                        <>
                            <IonListHeader>Kapitel</IonListHeader>
                            {icdsByCase['category']?.map((icd, index) =>
                                this.renderIcd(icd, index, false)
                            )}
                        </>
                    )}
                </IonList>
                {this.renderAddData()}
                {this.renderDeleteDialog()}
            </>
        )
    }

    render() {
        const { dataLoading, thisCase } = this.props
        const { popoverOpen, popoverContent } = this.state

        if (dataLoading) {
            return null
        }

        const dataAvailable =
            thisCase &&
            thisCase.hasOwnProperty('caseIcds') &&
            !isEmpty(thisCase['caseIcds'])

        return (
            <>
                <MyAccordion
                    title={`ICD (${
                        thisCase?.caseIcds ? thisCase?.caseIcds.length : '0'
                    })`}
                >
                    {dataAvailable
                        ? this.renderCaseIcds()
                        : this.renderAddData()}
                </MyAccordion>
                {popoverContent && (
                    <IonPopover
                        isOpen={Boolean(popoverOpen)}
                        onDidDismiss={() =>
                            this.setState({ popoverOpen: false })
                        }
                        side="left"
                        alignment="center"
                        className="popover-content"
                    >
                        <IonContent class="ion-padding">
                            <div>
                                <h3>{popoverContent?.code}</h3>
                                <p>{popoverContent?.label}</p>
                                {popoverContent?.codingHint && (
                                    <h4>Kodierungsempfehlung</h4>
                                )}
                                {popoverContent?.codingHint &&
                                typeof popoverContent?.codingHint ===
                                    'string' ? (
                                    <p>{popoverContent.codingHint}</p>
                                ) : (
                                    typeof popoverContent?.codingHint ===
                                        'object' &&
                                    Array.isArray(
                                        popoverContent?.codingHint?.paras
                                    ) &&
                                    popoverContent?.codingHint?.paras?.map(
                                        (para, index) => (
                                            <p key={index}>
                                                {para.class === 'bold' ? (
                                                    <b>{para.text}</b>
                                                ) : (
                                                    para.text
                                                )}
                                            </p>
                                        )
                                    )
                                )}
                                {popoverContent?.exclusion && (
                                    <h4>Ausschluss</h4>
                                )}
                                {popoverContent?.exclusion && (
                                    <p>{popoverContent.exclusion}</p>
                                )}

                                {popoverContent?.inclusion && (
                                    <h4>Einschluss</h4>
                                )}
                                {popoverContent?.inclusion && (
                                    <p>{popoverContent.inclusion}</p>
                                )}
                            </div>
                        </IonContent>
                    </IonPopover>
                )}
                {this.renderModal()}
            </>
        )
    }
}

const makeMapState = () => {
    const getIcdsByCase = makeIcdsByCase()

    return (state: RootState, ownProps: RouterProps) => {
        const caseId = parseInt(ownProps.match.params.caseId)
        const icdsByCase = getIcdsByCase(state, caseId)
        const caseById = selectCaseById(state, caseId)

        return {
            icdsByCase: icdsByCase,
            thisCase: caseById,
            caseId: caseId,
        }
    }
}

const mapDispatch = {
    updateCase,
}

const connector = connect(makeMapState, mapDispatch)

export default withRouter(connector(CaseIcd))
