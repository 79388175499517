import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { ConnectedProps, connect } from 'react-redux'
import {
    IonButtons,
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonGrid,
    IonRow,
    IonItem,
    IonButton,
    IonList,
    IonTextarea,
    IonBackButton,
    IonCol,
    IonCheckbox,
    IonIcon,
    IonAlert,
    IonicSafeString,
} from '@ionic/react'
import { informationCircleOutline } from 'ionicons/icons'
import { Formik, FormikProps, FormikValues } from 'formik'
import {
    updateCase,
    createCase,
    fetchCaseById,
    selectCaseById,
} from '../Case/CaseSlice'
import { remove } from 'lodash'
import { RootState } from '../../store'
import { CheckboxChangeEventDetail, IonCheckboxCustomEvent } from '@ionic/core'

const infos: Record<string, { header: string; message: IonicSafeString }> = {
    mainProblem: {
        header: 'Hauptproblem',
        message: new IonicSafeString(
            `Hauptprobleme zum jetzigen Zeitpunkt bzgl. Körperfunktion, Aktivität und Partizipation`
        ),
    },
    description: {
        header: 'Symptomverhalten',
        message:
            new IonicSafeString(`Subjektive Beschreibung der Beschwerden (über 24 Stunden hinweg)<br>
                                    Subjektive Intensität (unter Belastung/Entlastung)<br>
                                    Was verstärkt oder lindert die Symptome?<br>
                                    Prädisponierende Faktoren im Verhalten wie neue Aktivitäten, Überlastung<br>
                                    „Wie schränkt ihr Problem sie aus Ihrer Sicht im Alltag ein“`),
    },
    chronology: {
        header: 'Chronologie',
        message:
            new IonicSafeString(`Auslöser/Lindern der Beschwerden (onset/offset)<br>
                                    Behandlungen/Untersuchungen<br>
                                    Heilungsverlauf (erwartungsgemäß, verzögert?)<br>
                                    Zustand vor Beginn der Erkrankung oder der akuten Episode<br>
                                    Fragen zur Beurteilung des Stadiums und der Stabilität des Problems`),
    },
    contextfactors: {
        header: 'Kontextfaktoren',
        message:
            new IonicSafeString(`Allgemeine Belastungen, psychische Belastung<br>
                                    Coping (Was will der Patient/die Patientin zur Genesung beitragen?)<br>
                                    Yellow Flags: Kontrollüberzeugung, Katastrophisierung etc.<br>
                                    Einstellungen des Patienten, Gewohnheiten<br>
                                    Persönlichkeit (Eichhörnchen oder Bär)<br>
                                    Barrieren in der Lebensumgebung<br>
                                    Beruf<br>
                                    Hilfsmittel/Hilfe durch Personen`),
    },
    riskfactors: {
        header: 'Risikofaktoren',
        message: new IonicSafeString(`Nebenerkrankungen<br>
                                    Komorbiditäten<br>
                                    Komplikationen<br>
                                    Red Flags<br>
                                    Allg. Gesundheitszustand<br>
                                    Pathologien<br>
                                    Ungewollter Gewichtsverlust<br>
                                    Medikamente<br>
                                    Psychosoziale Risikofaktoren`),
    },
    aims: {
        header: 'Ziele',
        message:
            new IonicSafeString(`Wann wäre die Therapie für diesen Patienten erfolgreich?<br>
                                    Woran möchte er wieder teilhaben können?`),
    },
}

type AnamnesticCaseAnalysisProps = {}

type AnamnesticCaseAnalysisState = {
    isOpen: boolean
    initialDataLoading: boolean
    alertState: {
        isOpen?: boolean
        header?: string
        message?: IonicSafeString
    }
}

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = AnamnesticCaseAnalysisProps & RouterProps & PropsFromRedux

type RouterProps = RouteComponentProps<{
    patientId: string
    caseId: string
}>

class AnamnesticCaseAnalysis extends React.Component<
    Props,
    AnamnesticCaseAnalysisState
> {
    state: AnamnesticCaseAnalysisState = {
        isOpen: false,
        initialDataLoading: true,
        alertState: {
            isOpen: false,
        },
    }

    componentDidMount = async () => {
        const { fetchCaseById, caseId, isNew } = this.props
        if (!isNew) {
            this.setState({ initialDataLoading: true })

            await fetchCaseById({ id: caseId })
        }

        this.setState({ initialDataLoading: false })
    }

    handleCriteriaChange = (
        e: IonCheckboxCustomEvent<CheckboxChangeEventDetail>,
        props: FormikProps<FormikValues>
    ) => {
        let criteria = props.values.anamnesticCaseAnalysisCriteria ?? []
        if (e.detail.checked) {
            if (!criteria.includes(e.target.name)) {
                criteria.push(e.target.name)
            }
        } else {
            if (criteria.includes(e.target.name)) {
                remove(criteria, (item) => e.target.name === item)
            }
        }
        props.setFieldValue(`anamnesticCaseAnalysisCriteria`, criteria)
    }

    handleSubmitForm = async (values: FormikValues) => {
        const { isNew, caseId, patientId, createCase, updateCase, history } =
            this.props
        if (isNew) {
            const response = await createCase({ data: values }).unwrap()
            history.replace(
                `/patients/${patientId}/cases/${
                    Object.keys(response.entities.case)[0]
                }`
            )
        } else {
            await updateCase({ data: values, id: caseId })
            history.push(`/patients/${patientId}/cases/${caseId}`)
        }
    }

    handleInfoClick = (info: string) => {
        this.setState({ alertState: { ...infos[info], isOpen: true } })
    }

    renderFieldset = (props: FormikProps<FormikValues>) => {
        const {
            values: { anamnesticCaseAnalysisCriteria: criteria },
        } = props

        return (
            <IonGrid>
                <IonRow>
                    <IonCol size="3">
                        <IonList>
                            <IonItem key="mainProblem">
                                <IonCheckbox
                                    checked={criteria?.includes('mainProblem')}
                                    onIonChange={(e) =>
                                        this.handleCriteriaChange(e, props)
                                    }
                                    name="mainProblem"
                                    labelPlacement="end"
                                    justify="start"
                                >
                                    <b>Hauptproblem(e)</b>
                                    <br />
                                    (Leitsymptom)
                                </IonCheckbox>
                                <IonButton
                                    size="default"
                                    fill="clear"
                                    slot="end"
                                    onClick={() =>
                                        this.handleInfoClick('mainProblem')
                                    }
                                >
                                    <IonIcon
                                        slot="icon-only"
                                        icon={informationCircleOutline}
                                    />
                                </IonButton>
                            </IonItem>
                            <IonItem>
                                <IonCheckbox
                                    checked={criteria?.includes('description')}
                                    onIonChange={(e) =>
                                        this.handleCriteriaChange(e, props)
                                    }
                                    name="description"
                                    labelPlacement="end"
                                    justify="start"
                                >
                                    <b>Symptomverhalten</b>
                                    <br />
                                    der Symptome
                                </IonCheckbox>

                                <IonButton
                                    size="default"
                                    fill="clear"
                                    slot="end"
                                    onClick={() =>
                                        this.handleInfoClick('description')
                                    }
                                >
                                    <IonIcon
                                        slot="icon-only"
                                        icon={informationCircleOutline}
                                    />
                                </IonButton>
                            </IonItem>
                            {/* <IonItem> muss noch auf der Datenbank ausgebaut werden
                                <IonCheckbox
                                    checked={criteria?.includes(
                                        'suggestibility'
                                    )}
                                    onIonChange={(e) =>
                                        this.handleCriteriaChange(e, props)
                                    }
                                    labelPlacement="end"
                                    justify="start"
                                    name="suggestibility"
                                >
                                    <b>Beeinflussbarkeit</b>
                                    <br />
                                    der Symptome
                                </IonCheckbox>
                            </IonItem> */}
                            <IonItem>
                                <IonCheckbox
                                    checked={criteria?.includes('chronology')}
                                    onIonChange={(e) =>
                                        this.handleCriteriaChange(e, props)
                                    }
                                    labelPlacement="end"
                                    justify="start"
                                    name="chronology"
                                >
                                    <b>Chronologie</b>
                                    <br />
                                    des Hauptproblems
                                </IonCheckbox>
                                <IonButton
                                    size="default"
                                    fill="clear"
                                    slot="end"
                                    onClick={() =>
                                        this.handleInfoClick('chronology')
                                    }
                                >
                                    <IonIcon
                                        slot="icon-only"
                                        icon={informationCircleOutline}
                                    />
                                </IonButton>
                            </IonItem>
                            <IonItem>
                                <IonCheckbox
                                    checked={criteria?.includes('riskfactors')}
                                    onIonChange={(e) =>
                                        this.handleCriteriaChange(e, props)
                                    }
                                    labelPlacement="end"
                                    justify="start"
                                    name="riskfactors"
                                >
                                    <b>Risikofaktoren</b>
                                </IonCheckbox>
                                <IonButton
                                    size="default"
                                    fill="clear"
                                    slot="end"
                                    onClick={() =>
                                        this.handleInfoClick('riskfactors')
                                    }
                                >
                                    <IonIcon
                                        slot="icon-only"
                                        icon={informationCircleOutline}
                                    />
                                </IonButton>
                            </IonItem>
                            {/* <IonItem> ausgeblendet, muss noch in der Datenbank dann weg gemacht werden
                                <IonCheckbox
                                    checked={criteria?.includes('comorbidity')}
                                    onIonChange={(e) =>
                                        this.handleCriteriaChange(e, props)
                                    }
                                    labelPlacement="end"
                                    justify="start"
                                    name="comorbidity"
                                >
                                    <b>Komorbidität</b>
                                </IonCheckbox>
                            </IonItem> */}
                            <IonItem>
                                <IonCheckbox
                                    checked={criteria?.includes(
                                        'contextfactors'
                                    )}
                                    onIonChange={(e) =>
                                        this.handleCriteriaChange(e, props)
                                    }
                                    labelPlacement="end"
                                    justify="start"
                                    name="contextfactors"
                                >
                                    <b>Kontextfaktoren</b>
                                </IonCheckbox>
                                <IonButton
                                    size="default"
                                    fill="clear"
                                    slot="end"
                                    onClick={() =>
                                        this.handleInfoClick('contextfactors')
                                    }
                                >
                                    <IonIcon
                                        slot="icon-only"
                                        icon={informationCircleOutline}
                                    />
                                </IonButton>
                            </IonItem>
                            <IonItem>
                                <IonCheckbox
                                    checked={criteria?.includes('aims')}
                                    onIonChange={(e) =>
                                        this.handleCriteriaChange(e, props)
                                    }
                                    labelPlacement="end"
                                    justify="start"
                                    name="aims"
                                >
                                    <b>Ziele</b>
                                    <br />
                                    des Patienten
                                </IonCheckbox>
                                <IonButton
                                    size="default"
                                    fill="clear"
                                    slot="end"
                                    onClick={() => this.handleInfoClick('aims')}
                                >
                                    <IonIcon
                                        slot="icon-only"
                                        icon={informationCircleOutline}
                                    />
                                </IonButton>
                            </IonItem>
                        </IonList>
                    </IonCol>
                    <IonCol size="9">
                        <IonItem>
                            <IonTextarea
                                name="anamnesticCaseAnalysis"
                                autoGrow={true}
                                onIonChange={props.handleChange}
                                label="Anamnestische Fallanalyse"
                                labelPlacement="floating"
                                value={
                                    props.values.anamnesticCaseAnalysis ?? ''
                                }
                            ></IonTextarea>
                        </IonItem>

                        <IonItem>
                            <IonTextarea
                                name="anamnesticCaseAnalysisHint"
                                autoGrow={true}
                                onIonChange={props.handleChange}
                                label="Relevante Hinweise aus der Anamnese und Planung der weiteren Untersuchung"
                                labelPlacement="floating"
                                value={
                                    props.values.anamnesticCaseAnalysisHint ??
                                    ''
                                }
                            ></IonTextarea>
                        </IonItem>

                        <IonButton
                            style={{ float: 'right' }}
                            onClick={props.submitForm}
                        >
                            Speichern und zur Fallübersicht
                        </IonButton>
                    </IonCol>
                </IonRow>
            </IonGrid>
        )
    }

    renderAnamnesticCaseAnalysis = () => {
        const { thisCase, match } = this.props
        const { isOpen } = this.state

        return (
            <>
                <p>{thisCase?.['anamnesticCaseAnalysis']}</p>
                <div
                    style={{
                        width: '100%',
                        height: '72px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <IonButton
                        fill="clear"
                        routerLink={`${match.url}/anamnesticcaseanalysis`}
                        disabled={isOpen}
                        style={{
                            paddingTop: '16px',
                            height: '100%',
                        }}
                    >
                        Anamnestische Fallanalyse bearbeiten
                    </IonButton>
                </div>
            </>
        )
    }

    render() {
        const { caseId, isNew, patientId, thisCase } = this.props
        const {
            initialDataLoading,
            alertState: { isOpen, header, message },
        } = this.state

        if (initialDataLoading) {
            return null
        }

        return (
            <IonPage>
                <IonHeader>
                    <IonToolbar mode="ios">
                        <IonButtons slot="start">
                            <IonBackButton
                                text=""
                                defaultHref={`/patients/${patientId}/cases/${caseId}`}
                            ></IonBackButton>
                        </IonButtons>
                        <IonTitle>
                            {isNew
                                ? 'Neue Anamnestische Fallanalyse'
                                : 'Anamnestische Fallanalyse'}
                        </IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonContent fullscreen>
                    <Formik
                        initialValues={
                            isNew
                                ? ({
                                      patientId: patientId,
                                      anamnesticCaseAnalysisCriteria: [],
                                  } as FormikValues)
                                : { ...thisCase }
                        }
                        onSubmit={this.handleSubmitForm}
                    >
                        {(props) => (
                            <form
                                onSubmit={props.handleSubmit}
                                className="full-width"
                            >
                                {this.renderFieldset(props)}
                            </form>
                        )}
                    </Formik>
                    <IonAlert
                        isOpen={isOpen}
                        header={header}
                        message={message}
                        onDidDismiss={() => {
                            this.setState({ alertState: { isOpen: false } })
                        }}
                        buttons={[
                            {
                                text: 'Schließen',
                                role: 'cancel',
                            },
                        ]}
                    ></IonAlert>
                </IonContent>
            </IonPage>
        )
    }
}

const mapState = (state: RootState, ownProps: RouterProps) => {
    const caseId = ownProps.match.params.caseId
    const match = ownProps.match
    const patientId = parseInt(ownProps.match.params.patientId)

    return {
        thisCase: selectCaseById(state, parseInt(caseId)),
        isNew: caseId === 'new',
        match: match,
        patientId: patientId,
        caseId: parseInt(caseId),
    }
}

const mapDispatch = { updateCase, createCase, fetchCaseById }

const connector = connect(mapState, mapDispatch)

export default withRouter(connector(AnamnesticCaseAnalysis))
