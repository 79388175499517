import {
    IonButton,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonInput,
    IonPage,
    IonRow,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonIcon,
    IonSelect,
    IonSelectOption,
    IonBackButton,
    IonItem,
} from '@ionic/react'

import React from 'react'
import { ConnectedProps, connect } from 'react-redux'
import { isEmpty, isEqual } from 'lodash'
import { Formik, FormikProps, FormikValues } from 'formik'
import { logOutOutline, saveOutline } from 'ionicons/icons'
import { logoutUser } from '../Auth/SessionSlice'
import RoleEnum from '../../utils/Enum/RoleEnum'
import { fetchUserById, selectUserById, updateUser } from './UserSlice'
import { fetchAllCourses, selectAllCourses } from '../Course/CourseSlice'
import { RootState } from '../../store'
import { IonSelectCustomEvent, SelectChangeEventDetail } from '@ionic/core'
import { RouteComponentProps, withRouter } from 'react-router'

type UserDetailProps = {}

type UserDetailState = {
    dataLoading: boolean
}

type RouterProps = RouteComponentProps<{ id: string }>

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = UserDetailProps & PropsFromRedux & RouterProps

class UserDetail extends React.Component<Props, UserDetailState> {
    state = { dataLoading: true }

    componentDidMount() {
        const { fetchUserById, fetchAllCourses, userId } = this.props

        if (!userId) {
            return
        }

        this.setState({ dataLoading: true })
        Promise.all([fetchUserById({ id: userId }), fetchAllCourses()])
            .then(() => {
                this.setState({ dataLoading: false })
            })
            .catch(() => {
                this.setState({ dataLoading: false })
            })
    }

    handleSubmitForm = (values: FormikValues) => {
        const { updateUser, userId, history } = this.props

        if (!userId) {
            return
        }

        Promise.all([updateUser({ id: userId, data: values })])
            .then(() => {
                this.setState({ dataLoading: false })
                history.push('/users/')
            })
            .catch(() => {
                this.setState({ dataLoading: false })
            })
    }

    handleLogout = () => {
        const { logoutUser, history } = this.props

        Promise.all([logoutUser()]).then(() => {
            history.push('/')
        })
    }

    handleRoleChange = (
        e: IonSelectCustomEvent<SelectChangeEventDetail>,
        props: FormikProps<FormikValues>
    ) => {
        const { setFieldValue } = props

        console.log(e)

        if (isEqual(e.detail.value, RoleEnum.ROLE_ADMIN)) {
            setFieldValue('roles', [RoleEnum.ROLE_ADMIN], false)
        } else if (isEqual(e.detail.value, RoleEnum.ROLE_MOD)) {
            setFieldValue('roles', [RoleEnum.ROLE_MOD], false)
        } else {
            setFieldValue('roles', [RoleEnum.ROLE_USER], false)
        }
    }

    renderRoleSelect = (props: FormikProps<FormikValues>) => {
        const { authenticatedUser } = this.props
        let value

        if (props.values.roles.includes(RoleEnum.ROLE_ADMIN)) {
            value = RoleEnum.ROLE_ADMIN
        } else if (props.values.roles.includes(RoleEnum.ROLE_MOD)) {
            value = RoleEnum.ROLE_MOD
        } else {
            value = RoleEnum.ROLE_USER
        }

        return (
            <IonItem>
                <IonSelect
                    placeholder="Rolle"
                    interfaceOptions={{ header: 'Rolle' }}
                    onIonChange={(e) => this.handleRoleChange(e, props)}
                    value={value}
                    labelPlacement="floating"
                >
                    {authenticatedUser?.roles.includes(RoleEnum.ROLE_ADMIN) && (
                        <IonSelectOption value={RoleEnum.ROLE_ADMIN}>
                            Admin
                        </IonSelectOption>
                    )}
                    <IonSelectOption value={RoleEnum.ROLE_MOD}>
                        Moderator
                    </IonSelectOption>
                    <IonSelectOption value={RoleEnum.ROLE_USER}>
                        Student
                    </IonSelectOption>
                </IonSelect>
            </IonItem>
        )
    }

    renderFieldset = (props: FormikProps<FormikValues>) => {
        const { userId, authenticatedUser } = this.props
        const { handleChange, values, handleSubmit } = props

        return (
            <IonGrid>
                <IonRow>
                    <IonCol size="12" size-sm="6">
                        <IonItem>
                            <IonInput
                                name="email"
                                label="E-Mail"
                                placeholder="E-Mail"
                                labelPlacement="floating"
                                fill="solid"
                                value={values.email ? values.email : ''}
                                onIonChange={handleChange}
                            ></IonInput>
                        </IonItem>
                    </IonCol>
                    <IonCol size="12" size-sm="6">
                        <IonItem>
                            <IonInput
                                name="firstName"
                                placeholder="Vorname"
                                label="Vorname"
                                labelPlacement="floating"
                                fill="solid"
                                value={values.firstName ? values.firstName : ''}
                                onIonChange={handleChange}
                            ></IonInput>
                        </IonItem>
                    </IonCol>
                    <IonCol size="12" size-sm="6">
                        <IonItem>
                            <IonInput
                                name="lastName"
                                label="Nachname"
                                placeholder="Nachname"
                                labelPlacement="floating"
                                fill="solid"
                                value={values.lastName ? values.lastName : ''}
                                onIonChange={handleChange}
                            ></IonInput>
                        </IonItem>
                    </IonCol>
                    <IonCol size="12" size-sm="6">
                        <IonItem>
                            <IonSelect
                                placeholder="Kurse"
                                label="Kurse"
                                labelPlacement="floating"
                                fill="solid"
                                multiple={true}
                                onIonChange={handleChange}
                                name="courses"
                                value={values.courses}
                                style={{ maxWidth: '100% !important' }}
                            >
                                {this.renderCourseOptions()}
                            </IonSelect>
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol size="12" size-sm="6">
                        {this.renderRoleSelect(props)}
                    </IonCol>
                </IonRow>
                <IonRow className='className="ion-align-self-end"'>
                    <IonButton onClick={() => handleSubmit()}>
                        {' '}
                        <IonIcon icon={saveOutline} slot="start" />
                        Speichern
                    </IonButton>
                    {isEqual(userId, authenticatedUser?.id) && (
                        <IonButton color="danger" onClick={this.handleLogout}>
                            <IonIcon icon={logOutOutline} slot="start" />
                            Abmelden
                        </IonButton>
                    )}
                </IonRow>
            </IonGrid>
        )
    }

    renderCourseOptions = () => {
        const { courses } = this.props

        return courses.map((course, index) => {
            return (
                <IonSelectOption key={index} value={course.id}>
                    {course.name}
                </IonSelectOption>
            )
        })
    }

    render() {
        const { user } = this.props
        const { dataLoading } = this.state
        let initialValues = {}

        if (dataLoading || isEmpty(user) || isEmpty(user.roles)) {
            return null
        }

        initialValues = {
            ...user,
        }

        return (
            <IonPage>
                <IonHeader>
                    <IonToolbar>
                        <IonButtons slot="start">
                            <IonBackButton
                                text=""
                                defaultHref={`/dashboard/`}
                            ></IonBackButton>
                        </IonButtons>
                        <IonTitle>
                            Benutzer: {user.firstName} {user.lastName}
                        </IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonContent fullscreen>
                    <Formik
                        initialValues={initialValues}
                        onSubmit={this.handleSubmitForm}
                    >
                        {(props) => (
                            <form
                                onSubmit={props.handleSubmit}
                                className="full-width"
                            >
                                {this.renderFieldset(props)}
                            </form>
                        )}
                    </Formik>
                </IonContent>
            </IonPage>
        )
    }
}

const mapState = (state: RootState, ownProps: RouterProps) => {
    const {
        session: { user: authenticatedUser },
    } = state
    const id = parseInt(ownProps.match?.params?.id)

    return {
        user: selectUserById(state, id),
        userId: id ? id : null,
        courses: selectAllCourses(state),
        authenticatedUser: authenticatedUser,
    }
}

const mapDispatch = {
    fetchUserById,
    fetchAllCourses,
    updateUser,
    logoutUser,
}

const connector = connect(mapState, mapDispatch)

export default withRouter(connector(UserDetail))
