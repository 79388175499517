import React from 'react'
import {
    IonButton,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonInput,
    IonPage,
    IonRow,
    IonTitle,
    IonToolbar,
} from '@ionic/react'
import { Formik } from 'formik'
import { useAppDispatch } from '../../hooks'
import { useHistory } from 'react-router-dom'
import { loginUser } from '../Auth/SessionSlice'

export type LoginForm = {
    username?: string
    password?: string
}

const Login = (): JSX.Element => {
    const history = useHistory()
    const dispatch = useAppDispatch()

    const handleSubmitForm = async (values: LoginForm) => {
        try {
            const response = await dispatch(loginUser(values))
            if (!response.type.endsWith('rejected')) {
                history.push('/dashboard')
            }
        } catch (error) {
            console.log('login error', error)
        }
    }

    const initialValues = {}

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Login</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <Formik
                    initialValues={initialValues}
                    onSubmit={handleSubmitForm}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        /* and other goodies */
                    }) => (
                        <form onSubmit={handleSubmit} className="full-width">
                            <IonGrid
                                className="login-container"
                                justify-content-center
                            >
                                <IonRow>
                                    <IonCol>
                                        <IonInput
                                            name="email"
                                            label="E-Mail"
                                            labelPlacement="floating"
                                            onIonChange={handleChange}
                                            fill="outline"
                                        ></IonInput>
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol>
                                        <IonInput
                                            type="password"
                                            name="password"
                                            label="Passwort"
                                            labelPlacement="floating"
                                            onIonChange={handleChange}
                                            fill="outline"
                                        ></IonInput>
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol style={{ paddingTop: '20px' }}>
                                        <IonButton
                                            type="submit"
                                            expand="block"
                                            shape="round"
                                        >
                                            Login
                                        </IonButton>
                                    </IonCol>
                                </IonRow>
                                {/* <IonRow>
                                    <IonCol
                                        style={{
                                            textAlign: 'center',
                                            paddingTop: '20px',
                                        }}
                                    >
                                        <Link to="/forgotton">
                                            Passwort vergessen?
                                        </Link>
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol
                                        style={{
                                            textAlign: 'center',
                                            paddingTop: '20px',
                                        }}
                                    >
                                        <Link to="/register">
                                            Registrieren
                                        </Link>
                                    </IonCol>
                                </IonRow> */}
                            </IonGrid>
                        </form>
                    )}
                </Formik>
                <div
                    style={{
                        position: 'absolute',
                        bottom: 0,
                        right: 0,
                        opacity: 0.3,
                    }}
                >
                    {window.location.hostname}
                </div>
            </IonContent>
        </IonPage>
    )
}

export default Login
