import {
    createAsyncThunk,
    createEntityAdapter,
    createSlice,
} from '@reduxjs/toolkit'
import axiosInstance from '../../utils/ApiConfig'
import { normalize, schema } from 'normalizr'
import { Schemas } from '../../schemas'
import { RootState } from '../../store'
import { Course } from './CourseType'
import { fetchAllUsers, fetchUserById } from '../User/UserSlice'
import { fetchPatientById } from '../Patient/PatientSlice'

const courseAdapter = createEntityAdapter<Course>({
    sortComparer: (a, b) => a.id - b.id,
})

export const fetchAllCourses = createAsyncThunk('course/fetchAll', async () => {
    const response = await axiosInstance.get('/courses/')
    const normalized = normalize<
        schema.Array<Course>,
        {
            course: Record<string, Course>
        }
    >(response.data.data, Schemas.Courses)

    return { entities: normalized.entities, message: response.data.message }
})

const courseSlice = createSlice({
    name: 'course',
    initialState: courseAdapter.getInitialState({
        loading: false,
    }),
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchAllCourses.fulfilled, (state, action) => {
            const { course } = action.payload.entities

            if (course) {
                courseAdapter.upsertMany(state, course)
            }
        })
        builder.addCase(fetchAllUsers.fulfilled, (state, action) => {
            const { course } = action.payload.entities

            if (course) {
                courseAdapter.upsertMany(state, course)
            }
        })
        builder.addCase(fetchUserById.fulfilled, (state, action) => {
            const { course } = action.payload.entities

            if (course) {
                courseAdapter.upsertMany(state, course)
            }
        })
        builder.addCase(fetchPatientById.fulfilled, (state, action) => {
            const { course } = action.payload.entities

            if (course) {
                courseAdapter.upsertMany(state, course)
            }
        })
    },
})

export const {
    selectAll: selectAllCourses,
    selectById: selectCourseById,
    selectIds,
} = courseAdapter.getSelectors<RootState>((state) => state.course)

export default courseSlice.reducer
