import {
    TypedAddListener,
    TypedStartListening,
    addListener,
    configureStore,
    createListenerMiddleware,
} from '@reduxjs/toolkit'
import logger from 'redux-logger'
import rootReducer from './reducers'
//import toastMiddleware from './toastMiddleware'
//import { addToast } from './components/Toasts/toastSlice'
import { setupToastListener } from './utils/listenerMiddleware/toastListener'

/**
 * https://stackoverflow.com/questions/66315413/type-definitions-for-redux-toolkit-store-with-preloadedstate
 */

//const reducer = rootReducer
//const reducer = rootReducer
// const reducer = {
//     ...rootReducer,
//     auth: authReducer
// }

//const reducer = reduceReducers(rootReducer, authReducer)

//export type RootState = StateFromReducersMapObject<typeof rootReducer>

// listenerMiddleware.ts

// export const toastListener = startAppListening({
//     predicate: (action) => {
//         return action.payload?.message?.toast
//     },
//     effect: (action, listenerApi) => {
//         listenerApi.dispatch(
//             addToast({
//                 id: action.type,
//                 timestamp: Date.now(),
//                 message: action.payload?.message?.text,
//                 type: action.payload?.message?.type,
//             })
//         )
//     },
// })

const listenerMiddlewareInstance = createListenerMiddleware({
    onError: () => console.error,
})

// @see https://redux-toolkit.js.org/api/createListenerMiddleware#typescript-usage
export type AppStartListening = TypedStartListening<RootState, AppDispatch>
export type AppAddListener = TypedAddListener<RootState, AppDispatch>

export const startAppListening =
    listenerMiddlewareInstance.startListening as AppStartListening
export const addAppListener = addListener as AppAddListener

export type RootState = ReturnType<typeof rootReducer>

export function initStore() {
    return configureStore({
        reducer: rootReducer,
        middleware: (getDefaultMiddleware) => {
            const middleware = getDefaultMiddleware({
                serializableCheck: false,
            }).prepend(listenerMiddlewareInstance.middleware)

            if (process.env.NODE_ENV !== 'production') {
                middleware.push(logger)
            }

            return middleware
        },
        devTools: process.env.NODE_ENV !== 'production',
    })
}

setupToastListener(startAppListening)

type Store = ReturnType<typeof initStore>

export type AppDispatch = Store['dispatch']
