import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { ConnectedProps, connect } from 'react-redux'
import { IonButton } from '@ionic/react'
import { isEmpty } from 'lodash'
import MyAccordion from '../../../components/MyAccordion'
import AddDataButton from '../../../components/AddDataButton'
import { selectCaseById } from '../CaseSlice'
import { RootState } from '../../../store'

type AnamnesticCaseAnalysisProps = {}

type AnamnesticCaseAnalysisState = {}

type RouterProps = RouteComponentProps<{ caseId: string }>

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = AnamnesticCaseAnalysisProps & RouterProps & PropsFromRedux

class AnamnesticCaseAnalysis extends React.Component<
    Props,
    AnamnesticCaseAnalysisState
> {
    componentDidMount() {}

    renderAddData = () => {
        const { match } = this.props

        return (
            <div className="index-main">
                <p style={{ paddingTop: '16px' }}>Neue Anamnese aufnehmen</p>
                <AddDataButton
                    routerLink={`${match.url}/anamnesticcaseanalysis`}
                />
            </div>
        )
    }

    renderAnamnesticCaseAnalysis = () => {
        const { thisCase, match } = this.props

        return (
            <>
                <p>{thisCase!['anamnesticCaseAnalysis']}</p>
                <div
                    style={{
                        width: '100%',
                        height: '72px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <IonButton
                        fill="clear"
                        routerLink={`${match.url}/anamnesticcaseanalysis`}
                        style={{
                            paddingTop: '16px',
                            height: '100%',
                        }}
                    >
                        Anamnestische Fallanalyse bearbeiten
                    </IonButton>
                </div>
            </>
        )
    }

    render() {
        const { thisCase } = this.props

        const dataAvailable = !isEmpty(thisCase?.anamnesticCaseAnalysis)

        return (
            <>
                <MyAccordion
                    title="Anamnestische Fallanalyse"
                    showIcon={true}
                    checked={dataAvailable}
                >
                    {dataAvailable
                        ? this.renderAnamnesticCaseAnalysis()
                        : this.renderAddData()}
                </MyAccordion>
                {/*this.renderModal()*/}
            </>
        )
    }
}

const makeMapState = () => {
    return (state: RootState, ownProps: RouterProps) => {
        const caseId = parseInt(ownProps.match.params.caseId)
        const match = ownProps.match

        return {
            thisCase: selectCaseById(state, caseId),
            match: match,
        }
    }
}

const mapDispatch = {}

const connector = connect(makeMapState, mapDispatch)

export default withRouter(connector(AnamnesticCaseAnalysis))
