import {
    IonButton,
    IonChip,
    IonIcon,
    IonItem,
    IonLabel,
    IonText,
    useIonAlert,
} from '@ionic/react'
import { removeCircleOutline } from 'ionicons/icons'
import moment from 'moment'
import { FC, useCallback } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../hooks'
import {
    deleteTestEvaluationById,
    selectTestEvaluationById,
} from '../../../TestEvaluation/TestEvaluationSlice'
import { isEmpty } from 'lodash'
import { useHistory, useRouteMatch } from 'react-router'

type TestEvaluationProps = {
    testEvaluationId: number
}

const TestEvaluationItem: FC<TestEvaluationProps> = ({ testEvaluationId }) => {
    const testEvaluation = useAppSelector((state) =>
        selectTestEvaluationById(state, testEvaluationId)
    )
    const history = useHistory()
    const { url } = useRouteMatch()
    const [presentAlert] = useIonAlert()
    const dispatch = useAppDispatch()

    const handleDelete = useCallback(() => {
        dispatch(deleteTestEvaluationById({ id: testEvaluationId }))
    }, [dispatch, testEvaluationId])

    const handleDeleteClick = useCallback(
        (e: React.MouseEvent<HTMLIonButtonElement, MouseEvent>) => {
            e.stopPropagation()
            e.preventDefault()
            presentAlert({
                header: `Wollen Sie die Testauswertung vom ${moment(
                    testEvaluation.date
                ).format('L')} wirklich löschen?`,
                buttons: [
                    {
                        text: 'Abbrechen',
                        role: 'cancel',
                    },
                    {
                        text: 'Löschen',
                        role: 'delete',
                        handler: handleDelete,
                    },
                ],
            })
        },
        [handleDelete, presentAlert, testEvaluation.date]
    )

    if (!testEvaluation) {
        return <></>
    }

    const rejectedTestsCount = testEvaluation.rejectedTests?.length ?? 0

    const testLabel = !isEmpty(testEvaluation.tests)
        ? `${
              testEvaluation.tests!.length === 1
                  ? '1 Test'
                  : testEvaluation.tests!.length + ' Tests'
          } ${
              rejectedTestsCount > 0
                  ? ' (' + rejectedTestsCount + ' verworfen)'
                  : ''
          }`
        : null

    return (
        <IonItem
            button
            onClick={(e) => {
                e.preventDefault()
                history.push(`${url}/testevaluations/${testEvaluation.id}`)
            }}
        >
            <IonLabel>
                <div>
                    <IonText>
                        {moment(testEvaluation.date).format('llll')}
                    </IonText>
                </div>
                {(testLabel || !isEmpty(testEvaluation.icfs)) && (
                    <div style={{ paddingTop: '8px' }}>
                        <IonText>
                            {testLabel && (
                                <IonChip color="secondary">{testLabel}</IonChip>
                            )}
                            {!isEmpty(testEvaluation.icfs) &&
                                testEvaluation.icfs.map((icf) => {
                                    return (
                                        <IonChip key={icf} color="tertiary">
                                            {icf}
                                        </IonChip>
                                    )
                                })}
                        </IonText>
                    </div>
                )}
            </IonLabel>
            <IonButton
                onClick={handleDeleteClick}
                shape="round"
                fill="clear"
                slot="end"
            >
                <IonIcon
                    color="danger"
                    slot="icon-only"
                    icon={removeCircleOutline}
                />
            </IonButton>
        </IonItem>
    )
}

export default TestEvaluationItem
