import {
    IonBackButton,
    IonButton,
    IonButtons,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonInput,
    IonItem,
    IonPage,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonTitle,
    IonToolbar,
} from '@ionic/react'
import './Register.css'
import React from 'react'
import { Formik, FormikProps, FormikValues } from 'formik'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { registerUser } from '../Auth/SessionSlice'
import { ConnectedProps, connect } from 'react-redux'
import { isEqual } from 'lodash'
import { RootState } from '../../store'
import { IonSelectCustomEvent, SelectChangeEventDetail } from '@ionic/core'

const ROLE_ADMIN = 'ROLE_ADMIN'
const ROLE_MOD = 'ROLE_MOD'
const ROLE_USER = 'ROLE_USER'

type RegisterProps = {}

type RegisterState = {}

type RouterProps = RouteComponentProps

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = RegisterProps & PropsFromRedux & RouterProps

class Register extends React.Component<Props, RegisterState> {
    handleSubmitForm = async (values: FormikValues) => {
        const { registerUser, history } = this.props

        const response = await registerUser({ data: values })

        if (response.type === 'auth/register/fulfilled') {
            console.log('success')
            setTimeout(() => {
                history.push('/users')
            }, 1000)
        }
    }

    handleRoleChange = (
        e: IonSelectCustomEvent<SelectChangeEventDetail>,
        props: FormikProps<FormikValues>
    ) => {
        const { setFieldValue } = props

        console.log(e)

        if (isEqual(e.detail.value, ROLE_ADMIN)) {
            setFieldValue('roles', [ROLE_ADMIN], false)
        } else if (isEqual(e.detail.value, ROLE_MOD)) {
            setFieldValue('roles', [ROLE_MOD], false)
        } else {
            setFieldValue('roles', [ROLE_USER], false)
        }
    }

    renderRoleSelect = (props: FormikProps<FormikValues>) => {
        const { user } = this.props
        let value

        if (props.values.roles.includes(ROLE_ADMIN)) {
            value = ROLE_ADMIN
        } else if (props.values.roles.includes(ROLE_MOD)) {
            value = ROLE_MOD
        } else {
            value = ROLE_USER
        }

        return (
            <IonItem>
                <IonSelect
                    placeholder="Rolle"
                    interfaceOptions={{ header: 'Rolle' }}
                    onIonChange={(e) => this.handleRoleChange(e, props)}
                    value={value}
                >
                    {user!.roles.includes(ROLE_ADMIN) && (
                        <IonSelectOption value={ROLE_ADMIN}>
                            Admin
                        </IonSelectOption>
                    )}
                    <IonSelectOption value={ROLE_MOD}>
                        Moderator
                    </IonSelectOption>
                    <IonSelectOption value={ROLE_USER}>Student</IonSelectOption>
                </IonSelect>
            </IonItem>
        )
    }

    render() {
        const { user } = this.props
        const initialValues = { roles: [ROLE_USER] } as FormikValues

        if (!user) {
            return null
        }

        return (
            <IonPage>
                <IonHeader>
                    <IonToolbar>
                        <IonButtons slot="start">
                            <IonBackButton
                                text=""
                                defaultHref={`/users/`}
                            ></IonBackButton>
                        </IonButtons>
                        <IonTitle>Benutzer erstellen</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonContent fullscreen>
                    <Formik
                        initialValues={initialValues}
                        onSubmit={this.handleSubmitForm}
                    >
                        {(props) => (
                            <form
                                onSubmit={props.handleSubmit}
                                className="full-width"
                            >
                                <IonGrid
                                    className="register-container"
                                    justify-content-center
                                >
                                    <IonRow>
                                        <IonCol>
                                            <IonItem>
                                                <IonInput
                                                    fill="outline"
                                                    label="Vorname"
                                                    labelPlacement="floating"
                                                    name={'firstName'}
                                                    onIonChange={
                                                        props.handleChange
                                                    }
                                                />
                                            </IonItem>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol>
                                            <IonItem>
                                                <IonInput
                                                    fill="outline"
                                                    label="Nachname"
                                                    labelPlacement="floating"
                                                    name={'lastName'}
                                                    onIonChange={
                                                        props.handleChange
                                                    }
                                                />
                                            </IonItem>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol>
                                            <IonItem>
                                                <IonInput
                                                    fill="outline"
                                                    label="E-Mail"
                                                    labelPlacement="floating"
                                                    name={'email'}
                                                    onIonChange={
                                                        props.handleChange
                                                    }
                                                />
                                            </IonItem>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol>
                                            <IonItem>
                                                <IonInput
                                                    fill="outline"
                                                    label="Passwort"
                                                    labelPlacement="floating"
                                                    name={'password'}
                                                    onIonChange={
                                                        props.handleChange
                                                    }
                                                    type="password"
                                                />
                                            </IonItem>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol>
                                            {this.renderRoleSelect(props)}
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol>
                                            <IonButton
                                                disabled={props.isSubmitting}
                                                expand="block"
                                                shape="round"
                                                color="primary"
                                                type="submit"
                                                // onClick={() =>
                                                //     props.handleSubmit()
                                                // }
                                            >
                                                Nutzer erstellen
                                            </IonButton>
                                        </IonCol>
                                    </IonRow>
                                    {/* <IonRow>
                                        <IonCol>
                                            <IonButton
                                                disabled={props.isSubmitting}
                                                expand="block"
                                                shape="round"
                                                color="primary"
                                                fill="outline"
                                                onClick={() => {
                                                    this.props.history.push(
                                                        '/login'
                                                    )
                                                }}
                                            >
                                                Zurück zum Login
                                            </IonButton>
                                        </IonCol>
                                    </IonRow> */}
                                </IonGrid>
                            </form>
                        )}
                    </Formik>
                </IonContent>
            </IonPage>
        )
    }
}

const mapState = (state: RootState) => {
    const {
        session: { user },
    } = state

    return {
        user: user,
    }
}

const mapDispatch = { registerUser }

const connector = connect(mapState, mapDispatch)

export default withRouter(connector(Register))
