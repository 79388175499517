import { IonCheckbox, IonItem } from '@ionic/react'
import { FormikProps, FormikValues } from 'formik'
import { FC } from 'react'
import { exactSearch } from '../utils/IcfIcdHandler'
import { IcfIcdModeEnum } from '../utils/Enum/IcfIcdEnum'
import { useAppSelector } from '../hooks'
import { selectCaseById } from '../pages/Case/CaseSlice'

type IcfButtonProps = {
    icf: string
    props: FormikProps<FormikValues>
    caseId: number
}

const IcfButton: FC<IcfButtonProps> = ({ icf, props, caseId }) => {
    const { values, setFieldValue } = props
    const caseById = useAppSelector((state) => selectCaseById(state, caseId))

    console.log(icf)

    let completeIcf = exactSearch(icf, IcfIcdModeEnum.MODE_ICF)

    if (!completeIcf) {
        return null
    }
    return (
        <IonItem
            lines="none"
            onClick={() => {
                let docuIcfs = [...values.documentationIcfs] ?? []

                if (docuIcfs.includes(icf)) {
                    docuIcfs.splice(docuIcfs.indexOf(icf), 1)
                    setFieldValue('documentationIcfs', docuIcfs)
                } else {
                    docuIcfs.push(icf)
                    setFieldValue('documentationIcfs', docuIcfs)
                }
            }}
        >
            <IonCheckbox
                slot="start"
                justify="start"
                labelPlacement="end"
                checked={values?.documentationIcfs?.includes(icf)}
            >
                {completeIcf.code +
                    (caseById?.personalFactors ? 'i' : '') +
                    ' - ' +
                    completeIcf.label}
            </IonCheckbox>
        </IonItem>
    )
}

export default IcfButton
