import {
    createAsyncThunk,
    createEntityAdapter,
    createSlice,
} from '@reduxjs/toolkit'
import { Patient } from './PatientType'
import { RootState } from '../../store'
import axiosInstance from '../../utils/ApiConfig'
import { normalize, schema } from 'normalizr'
import { Schemas } from '../../schemas'
import { FormikValues } from 'formik'
import { Course } from '../Course/CourseType'
import { Case } from '../Case/CaseType'

const patientAdapter = createEntityAdapter<Patient>({
    sortComparer: (a, b) => a.id - b.id,
})

export const fetchAllPatients = createAsyncThunk(
    'patient/fetchAll',
    async () => {
        const response = await axiosInstance.get('/patients/')
        const normalized = normalize<
            schema.Array<Patient>,
            {
                patient: Record<string, Patient>
            }
        >(response.data.data, Schemas.Patients)

        return { entities: normalized.entities, message: response.data.message }
    }
)

export const fetchPatientById = createAsyncThunk(
    'patient/fetchById',
    async (params: { id: number }) => {
        const { id } = params

        const response = await axiosInstance.get(`/patients/${id}`)
        const normalized = normalize<
            schema.Entity<Patient>,
            {
                patient: Record<string, Patient>
                course: Record<string, Course>
                case: Record<string, Case>
            }
        >(response.data.data, Schemas.Patient)

        return { entities: normalized.entities, message: response.data.message }
    }
)

export const updatePatient = createAsyncThunk(
    'patient/update',
    async (params: { id: number | string; data: FormikValues }) => {
        const { id, data } = params
        const response = await axiosInstance.put(`/patients/${id}`, {
            ...data,
        })
        const normalized = normalize<
            schema.Entity<Patient>,
            {
                patient: Record<string, Patient>
            }
        >(response.data.data, Schemas.Patient)

        return { entities: normalized.entities, message: response.data.message }
    }
)

export const createPatient = createAsyncThunk(
    'patient/create',
    async (params: { data: FormikValues }) => {
        const { data } = params
        const response = await axiosInstance.post('/patients/', {
            ...data,
        })
        const normalized = normalize<
            schema.Entity<Patient>,
            {
                patient: Record<string, Patient>
            }
        >(response.data.data, Schemas.Patient)

        return { entities: normalized.entities, message: response.data.message }
    }
)

export const deletePatientById = createAsyncThunk(
    'patient/delete',
    async (params: { id: number }) => {
        const { id } = params

        const response = await axiosInstance.delete(`/patients/${id}`)

        return { id: id, message: response.data.message }
    }
)

const patientSlice = createSlice({
    name: 'patient',
    initialState: patientAdapter.getInitialState({}),
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchAllPatients.fulfilled, (state, action) => {
            const { patient } = action.payload.entities

            if (patient) {
                patientAdapter.upsertMany(state, patient)
            }
        })
        builder.addCase(fetchPatientById.fulfilled, (state, action) => {
            const { patient } = action.payload.entities

            if (patient) {
                patientAdapter.upsertMany(state, patient)
            }
        })
        builder.addCase(createPatient.fulfilled, (state, action) => {
            const { patient } = action.payload.entities

            if (patient) {
                patientAdapter.upsertMany(state, patient)
            }
        })
        builder.addCase(updatePatient.fulfilled, (state, action) => {
            const { patient } = action.payload.entities

            if (patient) {
                patientAdapter.upsertMany(state, patient)
            }
        })
        builder.addCase(deletePatientById.fulfilled, (state, action) => {
            const { id } = action.payload

            patientAdapter.removeOne(state, id)
        })
    },
})

export const {
    selectAll: selectAllPatients,
    selectById: selectPatientById,
    selectIds: selectPatientIds,
} = patientAdapter.getSelectors<RootState>((state) => state.patient)

export default patientSlice.reducer
