import React from 'react'
import RoleEnum from '../utils/Enum/RoleEnum'
import { useAppSelector } from '../hooks'
import { Redirect } from 'react-router'
import { intersection } from 'lodash'

type AclProps = {
    allowedRoles: RoleEnum[]
    children: React.ReactNode
}

const ACL = (props: AclProps): JSX.Element => {
    const { allowedRoles, children } = props
    const userRoles = useAppSelector((state) => state.session?.user?.roles)
    const loggedIn = useAppSelector((state) => state.session?.loggedIn)
    const loading = useAppSelector((state) => state.session?.loading)

    // Überprüfen, ob mindestens eine der erlaubten Rollen im Redux State vorhanden ist
    const isAuthorized = intersection(allowedRoles, userRoles).length >= 1

    if (loading) {
        return <></>
    }

    if (isAuthorized && loggedIn && !loading) {
        return <>{children}</> // Rendert die children-Komponenten
    } else {
        console.warn('ACL redirect to login')
        return <Redirect to="/login" /> // Navigiert zur "/login"-Route, wenn nicht autorisiert
    }
}

export default ACL
