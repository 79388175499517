import { IonButton, IonIcon } from '@ionic/react'
import { addOutline } from 'ionicons/icons'

type ButtonProps = {
    routerLink?: string
    disabled?: boolean
    onClick?: () => void
}

const AddDataButton = (props: ButtonProps): JSX.Element => {
    const { onClick, routerLink, disabled } = props

    return (
        <IonButton
            routerLink={routerLink}
            onClick={onClick}
            disabled={disabled}
            shape="round"
            strong
        >
            <IonIcon slot="icon-only" icon={addOutline} />
        </IonButton>
    )
}

export default AddDataButton
