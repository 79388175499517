import React from 'react'
import { Route, useRouteMatch } from 'react-router-dom'
import { IonPage, IonRouterOutlet } from '@ionic/react'
import CaseOverview from './CaseOverview'
import DocumentationIndex from '../Documentation/DocumentationIndex'
import AnamnesticCaseAnalysis from '../AnamnesticCaseAnalysis/AnamnesticCaseAnalysis'
import AimIndex from '../Aim/AimIndex'
import TestEvaluationIndex from '../TestEvaluation/TestEvaluationIndex'

const Case = (): JSX.Element => {
    const { path } = useRouteMatch()

    return (
        <IonPage>
            <IonRouterOutlet>
                {/* <Route exact path={match.url} component={PatientList} /> */}
                <Route
                    exact
                    path={`${path}/:caseId/`}
                    component={CaseOverview}
                />
                <Route
                    path={`${path}/:caseId/documentations`}
                    component={DocumentationIndex}
                />
                <Route
                    path={`${path}/:caseId/anamnesticcaseanalysis`}
                    component={AnamnesticCaseAnalysis}
                />
                <Route path={`${path}/:caseId/aims`} component={AimIndex} />
                <Route
                    path={`${path}/:caseId/testevaluations`}
                    component={TestEvaluationIndex}
                />

                {/* <Route
                        path={`${match.url}/new/`}
                        component={CaseOverview}
                    /> */}
                {/* <Route path="/cases/new" component={CaseNew} /> */}
                {/* <Route path="/cases/edit/:id" component={PatientEdit} /> */}
            </IonRouterOutlet>
        </IonPage>
    )
}

export default Case
