import { IonToast } from '@ionic/react'
import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { Toast } from './ToastType'
import { removeToast, selectAllToasts } from './toastSlice'

const Toasts = (): JSX.Element => {
    const [currentToast, setCurrentToast] = useState<Toast | null>(null)

    const allToasts = useAppSelector(selectAllToasts)
    const dispatch = useAppDispatch()

    useEffect(() => {
        if (!currentToast) {
            if (allToasts.length > 0) {
                setCurrentToast(allToasts[0])
            }
        }
    }, [allToasts, currentToast])

    const onToastDismiss = () => {
        dispatch(removeToast(currentToast?.id!))
        setCurrentToast(null)
    }

    return (
        <>
            <IonToast
                isOpen={Boolean(currentToast)}
                color={
                    currentToast?.type === 'error'
                        ? 'danger'
                        : currentToast?.type === 'info'
                        ? 'info'
                        : 'success'
                }
                onDidDismiss={onToastDismiss}
                message={currentToast?.message}
                duration={5000}
            />
            {/* <IonToast
                isOpen={successToastOpen}
                color="success"
                onDidDismiss={() => {
                    setSuccessToastOpen(false)
                }}
                message={'success'}
                duration={5000}
            /> */}
        </>
    )
}

export default Toasts
